// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {nd1BP5t0m: {hover: true}};

const cycleOrder = ["nd1BP5t0m"];

const variantClassNames = {nd1BP5t0m: "framer-v-7ftoml"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "nd1BP5t0m", tap: wIyFaRQ7U, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "nd1BP5t0m", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap11y2nsq = activeVariantCallback(async (...args) => {
if (wIyFaRQ7U) {
const res = await wIyFaRQ7U(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YYq5x", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-7ftoml", className)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"nd1BP5t0m"} onTap={onTap11y2nsq} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgba(45, 33, 53, 0.7))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-81ab93ff-d515-4aca-b63b-24f83b57a3ab, rgba(31, 23, 34, 0.5))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} transition={transition} variants={{"nd1BP5t0m-hover": {"--border-color": "var(--token-9ed7888c-aeb5-48dd-aee6-b885d99628b4, rgb(64, 52, 72))", backgroundColor: "var(--token-b4804a4b-1cf7-4023-83f4-858a0ef62630, rgba(44, 33, 51, 0.5))"}}} {...addPropertyOverrides({"nd1BP5t0m-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-191uydj-container"} layoutDependency={layoutDependency} layoutId={"eN4yV2ulG-container"} transition={transition}><Phosphor color={"var(--token-efbc6c56-80fe-4475-9b37-9684d6e92632, rgb(239, 237, 253)) /* {\"name\":\"icon/default\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"X"} id={"eN4yV2ulG"} layoutId={"eN4yV2ulG"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YYq5x [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YYq5x .framer-f9aw18 { display: block; }", ".framer-YYq5x .framer-7ftoml { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 22px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 22px; }", ".framer-YYq5x .framer-191uydj-container { flex: none; height: 14px; position: relative; width: 14px; }", ".framer-YYq5x .framer-v-7ftoml .framer-7ftoml { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-YYq5x .framer-7ftoml { gap: 0px; } .framer-YYq5x .framer-7ftoml > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-YYq5x .framer-7ftoml > :first-child { margin-left: 0px; } .framer-YYq5x .framer-7ftoml > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 22
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uKtRiaYaO":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wIyFaRQ7U":"tap"}
 */
const FramernvPPUx7MY: React.ComponentType<Props> = withCSS(Component, css, "framer-YYq5x") as typeof Component;
export default FramernvPPUx7MY;

FramernvPPUx7MY.displayName = "Window Close";

FramernvPPUx7MY.defaultProps = {height: 22, width: 22};

addPropertyControls(FramernvPPUx7MY, {wIyFaRQ7U: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramernvPPUx7MY, [...PhosphorFonts])